import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { LoginComponent } from "./components/login/login.component";
export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "formats",
    loadChildren: "./public/formats/formats.module#FormatsModule",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./components/dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "inventario",
        loadChildren:
          "./components/inventario/inventario.module#InventarioModule",
      },
      {
        path: "catalogos",
        loadChildren: "./components/catalogos/catalogos.module#CatalogosModule",
      },
      {
        path: "ingresos",
        loadChildren: "./components/ingresos/ingresos.module#IngresosModule",
      },
      {
        path: "egresos",
        loadChildren: "./components/egresos/egresos.module#EgresosModule",
      },
      {
        path: "reportes",
        loadChildren: "./components/reportes/reportes.module#ReportesModule",
      },
      {
        path: "config",
        loadChildren: "./components/config/config.module#ConfigModule",
      },
    ],
  },
];
