import { Component, OnInit } from "@angular/core";

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  children?: ChildrenItems[];
  permission?: string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab?: string;
  type?: string;
  permission?: string;
  icontype: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Inicio",
    type: "link",
    icontype: "nc-icon nc-bank",
  },
  {
    path: "/ingresos",
    title: "Eventos / ingresos",
    type: "link",
    icontype: "fal fa-money-bill-alt",
    permission: "INGRESOS",
  },
  {
    path: "/egresos",
    title: "Egresos",
    type: "link",
    icontype: "fal fa-wallet",
    permission: "EGRESOS",
  },
  // {
  //   path: '/reportes',
  //   title: 'Reportes',
  //   type: 'sub',
  //   icontype: 'fal fa-user-chart',
  //   children: [],
  //   permission: "REPORTES",
  // },
  {
    path: "/inventario",
    title: "Inventario",
    type: "sub",
    icontype: "fal fa-warehouse-alt",
    children: [
      {
        path: "movimientos",
        title: "Movimientos",
        icontype: "fal fa-dolly",
        permission: "INVENTARIO",
      },
      {
        path: "registro",
        title: "Registro",
        icontype: "fal fa-cabinet-filing",
        permission: "INVENTARIO",
      },
    ],
  },
  {
    path: "/reportes",
    title: "Reportes",
    type: "sub",
    icontype: "fal fa-file-chart-line",
    children: [
      {
        path: "ingresos",
        title: "Ingresos",
        icontype: "fal fa-chart-line",
        permission: "REPORTE INGRESOS",
      },
      {
        path: "egresos",
        title: "Egresos",
        icontype: "fal fa-chart-line-down",
        permission: "REPORTE EGRESOS",
      },
      {
        path: "utilidades",
        title: "Utilidades",
        icontype: "fal fa-project-diagram",
        permission: "REPORTE UTILIDADES",
      },
    ],
  },
  {
    path: "/catalogos",
    title: "Catálogos",
    type: "sub",
    icontype: "fal fa-archive",
    children: [
      {
        path: "clientes",
        title: "Clientes",
        icontype: "fal fa-user",
        permission: "CLIENTES",
      },
      {
        path: "conceptos-ingresos",
        title: "Conceptos de ingresos",
        icontype: "fal fa-money-bill-alt",
        permission: "CONCEPTOS DE INGRESOS",
      },
      {
        path: "conceptos-egresos",
        title: "Conceptos de egresos",
        icontype: "fal fa-wallet",
        permission: "CONCEPTOS DE EGRESOS",
      },
      {
        path: "conceptos-extra",
        title: "Conceptos extra",
        icontype: "fal fa-plus",
        permission: "CONCEPTOS EXTRA",
      },
      {
        path: "conceptos-paquete",
        title: "Conceptos paquete",
        icontype: "fal fa-box",
        permission: "CONCEPTOS PAQUETES",
      },
      {
        path: "salones",
        title: "Salones",
        icontype: "fal fa-building",
        permission: "SALONES",
      },
      {
        path: "paquetes",
        title: "Paquetes",
        icontype: "fal fa-boxes",
        permission: "PAQUETES",
      },
      {
        path: "productos",
        title: "Productos",
        icontype: "fal fa-store",
        permission: "PRODUCTOS",
      },
      {
        path: "proveedores",
        title: "Proveedores",
        icontype: "fal fa-truck",
        permission: "PROVEEDORES",
      },
      {
        path: "tipo-eventos",
        title: "Tipo de eventos",
        icontype: "fal fa-gifts",
        permission: "TIPO DE EVENTOS",
      },
      {
        path: "usuarios",
        title: "Usuarios",
        icontype: "fal fa-users",
        permission: "USUARIOS",
      },
    ],
  },
  {
    path: "/config",
    title: "Configuracion",
    type: "link",
    icontype: "fal fa-cog",
    permission: "CONFIGURACION",
  },
];

@Component({
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }
  private token = JSON.parse(localStorage.getItem("jwtToken"));
  public perm = this.token.user.permisos;
  public user = this.token.user;

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
}
