import { Injectable } from "@angular/core";

@Injectable()
export class Globals {
  apiUrl: string = "https://apilivin.hdsoftware.mx";
  permisosdisponibles = [
    "CLIENTES",
    "CONCEPTOS DE INGRESOS",
    "CONCEPTOS DE EGRESOS",
    "CONCEPTOS EXTRA",
    "CONCEPTOS PAQUETES",
    "CONCEPTOS DESGLOSAR PRECIOS",
    "DASHBOARD",
    "INGRESOS",
    "CANCELAR INGRESOS",
    "EGRESOS",
    "CANCELAR EGRESOS",
    "PAQUETES",
    "SALONES",
    "TIPO DE EVENTOS",
    "USUARIOS",
    "CONFIGURACION",
    "PROVEEDORES",
    "PRODUCTOS",
    "INVENTARIO",
    "REPORTE INGRESOS",
    "REPORTE EGRESOS",
    "REPORTE UTILIDADES",
  ];
  metodos = ["efectivo", "transferencia", "tarjeta"];
  horas = [
    { display: "00:00", hour: "00", minute: "00" },
    { display: "00:15", hour: "00", minute: "15" },
    { display: "00:30", hour: "00", minute: "30" },
    { display: "00:45", hour: "00", minute: "45" },
    { display: "01:00", hour: "01", minute: "00" },
    { display: "01:15", hour: "01", minute: "15" },
    { display: "01:30", hour: "01", minute: "30" },
    { display: "01:45", hour: "01", minute: "45" },
    { display: "02:00", hour: "02", minute: "00" },
    { display: "02:15", hour: "02", minute: "15" },
    { display: "02:30", hour: "02", minute: "30" },
    { display: "02:45", hour: "02", minute: "45" },
    { display: "03:00", hour: "03", minute: "00" },
    { display: "03:15", hour: "03", minute: "15" },
    { display: "03:30", hour: "03", minute: "30" },
    { display: "03:45", hour: "03", minute: "45" },
    { display: "04:00", hour: "04", minute: "00" },
    { display: "04:15", hour: "04", minute: "15" },
    { display: "04:30", hour: "04", minute: "30" },
    { display: "04:45", hour: "04", minute: "45" },
    { display: "05:00", hour: "05", minute: "00" },
    { display: "05:15", hour: "05", minute: "15" },
    { display: "05:30", hour: "05", minute: "30" },
    { display: "05:45", hour: "05", minute: "45" },
    { display: "06:00", hour: "06", minute: "00" },
    { display: "06:15", hour: "06", minute: "15" },
    { display: "06:30", hour: "06", minute: "30" },
    { display: "06:45", hour: "06", minute: "45" },
    { display: "07:00", hour: "07", minute: "00" },
    { display: "07:15", hour: "07", minute: "15" },
    { display: "07:30", hour: "07", minute: "30" },
    { display: "07:45", hour: "07", minute: "45" },
    { display: "08:00", hour: "08", minute: "00" },
    { display: "08:15", hour: "08", minute: "15" },
    { display: "08:30", hour: "08", minute: "30" },
    { display: "08:45", hour: "08", minute: "45" },
    { display: "09:00", hour: "09", minute: "00" },
    { display: "09:15", hour: "09", minute: "15" },
    { display: "09:30", hour: "09", minute: "30" },
    { display: "09:45", hour: "09", minute: "45" },
    { display: "10:00", hour: "10", minute: "00" },
    { display: "10:15", hour: "10", minute: "15" },
    { display: "10:30", hour: "10", minute: "30" },
    { display: "10:45", hour: "10", minute: "45" },
    { display: "11:00", hour: "11", minute: "00" },
    { display: "11:15", hour: "11", minute: "15" },
    { display: "11:30", hour: "11", minute: "30" },
    { display: "11:45", hour: "11", minute: "45" },
    { display: "12:00", hour: "12", minute: "00" },
    { display: "12:15", hour: "12", minute: "15" },
    { display: "12:30", hour: "12", minute: "30" },
    { display: "12:45", hour: "12", minute: "45" },
    { display: "13:00", hour: "13", minute: "00" },
    { display: "13:15", hour: "13", minute: "15" },
    { display: "13:30", hour: "13", minute: "30" },
    { display: "13:45", hour: "13", minute: "45" },
    { display: "14:00", hour: "14", minute: "00" },
    { display: "14:15", hour: "14", minute: "15" },
    { display: "14:30", hour: "14", minute: "30" },
    { display: "14:45", hour: "14", minute: "45" },
    { display: "15:00", hour: "15", minute: "00" },
    { display: "15:15", hour: "15", minute: "15" },
    { display: "15:30", hour: "15", minute: "30" },
    { display: "15:45", hour: "15", minute: "45" },
    { display: "16:00", hour: "16", minute: "00" },
    { display: "16:15", hour: "16", minute: "15" },
    { display: "16:30", hour: "16", minute: "30" },
    { display: "16:45", hour: "16", minute: "45" },
    { display: "17:00", hour: "17", minute: "00" },
    { display: "17:15", hour: "17", minute: "15" },
    { display: "17:30", hour: "17", minute: "30" },
    { display: "17:45", hour: "17", minute: "45" },
    { display: "18:00", hour: "18", minute: "00" },
    { display: "18:15", hour: "18", minute: "15" },
    { display: "18:30", hour: "18", minute: "30" },
    { display: "18:45", hour: "18", minute: "45" },
    { display: "19:00", hour: "19", minute: "00" },
    { display: "19:15", hour: "19", minute: "15" },
    { display: "19:30", hour: "19", minute: "30" },
    { display: "19:45", hour: "19", minute: "45" },
    { display: "20:00", hour: "20", minute: "00" },
    { display: "20:15", hour: "20", minute: "15" },
    { display: "20:30", hour: "20", minute: "30" },
    { display: "20:45", hour: "20", minute: "45" },
    { display: "21:00", hour: "21", minute: "00" },
    { display: "21:15", hour: "21", minute: "15" },
    { display: "21:30", hour: "21", minute: "30" },
    { display: "21:45", hour: "21", minute: "45" },
    { display: "22:00", hour: "22", minute: "00" },
    { display: "22:15", hour: "22", minute: "15" },
    { display: "22:30", hour: "22", minute: "30" },
    { display: "22:45", hour: "22", minute: "45" },
    { display: "23:00", hour: "23", minute: "00" },
    { display: "23:15", hour: "23", minute: "15" },
    { display: "23:30", hour: "23", minute: "30" },
    { display: "23:45", hour: "23", minute: "45" },
  ];
}
